class Position {
	x: number;
	y: number;

	constructor(x: number, y: number) {
		this.x = x;
		this.y = y;
	}
}

class AppearData {
	opacity?: number;
	position?: Position;
	scrollTrigger?: AppearScrollTriggerData;
}

class AppearScrollTriggerData {
	scrub: number;
	start: string;
	end: string;

	constructor() {
		this.scrub = 0;
		this.start = "";
		this.end = "";
	}
}

class AppearOptions {
	from?: AppearData;
	to?: AppearData;
	delay?: number;
	duration: number;
	ease: string;

	constructor(duration: number) {
		this.duration = duration;
		this.ease = "ease-in-out";
	}
}

export { AppearOptions, AppearData, Position, AppearScrollTriggerData };